import axiosInstance from "../../axios/axios";

export const GetDestroyedDrugsApi = async (Data) =>
  await axiosInstance.post(`/vozo/GetDestroyedDrugs`, Data);

export const GetCPT4CodesWorkApi = async (Data) =>
  await axiosInstance.get(`/vozo/cpt4code?search=` + Data);

export const GetICD9CodesWorkApi = async (Data) =>
  await axiosInstance.get(
    `https://clinicaltables.nlm.nih.gov/api/icd9cm_dx/v3/search?terms=` + Data
  );

export const GetICD10CodesWorkApi = async (Data) =>
  await axiosInstance.get(`/vozo/icd10?search=` + Data);

export const GetHCPCSCodesWorkApi = async (Data) =>
  await axiosInstance.get(`/vozo/hcpcs?search=` + Data);

// export const GetCPT4CodesWorkApi = async (Data) => {
//   console.log(Data);
//   if (Data === "CPT4") {
//     await axiosInstance.get(`/vozo/cpt4code`);
//   } else if (Data === "ICD9") {
// await axiosInstance.get(
//   `https://clinicaltables.nlm.nih.gov/api/icd9cm_dx/v3/search`
// );
//   } else if (Data === "ICD10") {
// await axiosInstance.get(
//   `https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name`
// );
//   } else if (Data === "HCPCS") {
// await axiosInstance.get(
//   `https://clinicaltables.nlm.nih.gov/api/hcpcs/v3/search`
// );
//   }
// };
